import ScrollReveal from "scrollreveal";

export class Animations {
    constructor() {
        const els = document.querySelectorAll('[data-anim]');

        if (els.length > 0) {
            this.init(els);
        }
    }

    init(els) {
        els.forEach((el) => {
            if (el.dataset.anim) {
                ScrollReveal().reveal(el, {
                    delay: el.getAttribute('data-anim-delay') ? el.getAttribute('data-anim-delay') : 0,
                    duration: el.getAttribute('data-anim-duration') ? el.getAttribute('data-anim-duration') : 1000,
                    distance: el.getAttribute('data-anim-distance') ? el.getAttribute('data-anim-distance') : '100px',
                    easing: el.getAttribute('data-anim-easing') ? el.getAttribute('data-anim-easing') : 'cubic-bezier(.1,.79,.27,1)',
                    origin: el.getAttribute('data-anim') ? el.getAttribute('data-anim') : 'left',
                    reset: true,
                    viewFactor: el.getAttribute('data-anim-viewFactor') ? el.getAttribute('data-anim-viewFactor') : 0.25,
                })
            }
        })
    }
}
