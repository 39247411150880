import Swiper from "swiper";
import {Navigation, Autoplay} from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";

export class Slider {

    constructor() {

        new Swiper('.swiper_textimage', {
            slidesPerView: 1,
            spaceBetween: 30,
            slidesPerGroup: 1,
            modules: [Navigation, Autoplay],
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            autoplay: {
                delay: 2000,
            },
        })
    }
}
