import '@css/main.scss';
import {Header} from './components/Header';
import {Menu} from './components/Menu';
import {Lightbox} from './components/Lightbox';
import {Tabs} from './components/Tabs';
import {Slider} from "./components/Slider";
import {Parallax} from "./components/Parallax";
import {Animations} from "./components/Animations";
import { DropDownMobile } from './components/DropDownMobile';


window.onload = () => {
    new Header()
    new Menu()
    new Lightbox()
    new Tabs()
    new Slider()
    new Parallax()
    new Animations()
    new DropDownMobile()
    new Lightbox()
}
