export class DropDownMobile {
    constructor() {
        const els = document.querySelectorAll('.menu-item-has-children')

        if (els.length > 0) {
            this.init(els)
        }
    }

    init(els) {
        els.forEach(element => {
            element.addEventListener('click', function (e) {
                let el = e.currentTarget.querySelector('.sub-menu');
                console.log(el);
                el.classList.toggle('show');
            })
        });
    }
}