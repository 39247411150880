import GLightbox from 'glightbox';
export class Lightbox {
    constructor()
    {

        this.init();
    }

    init()
    {
        const gallery = GLightbox({
            touchNavigation: true,
        })
    }
}
